import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import SVG from 'react-inlinesvg'

import Section from '@components/Section'
import Sticky, { StickyState } from '@components/Sticky'
import HorizontalScroll from '@components/HorizontalScroll'
import media from '@styles/media'
import { clamp, useResize } from '@utils'

import AboutHeading from './About.Heading'

const BACKGROUND_COLOR_BLENDS = [
  '#1D2128',
  '#1C1F26',
  '#1A1E24',
  '#191C23',
  '#181B21',
  '#17191F',
  '#15181D',
  '#14161B',
  '#131519',
  '#121318',
  '#101216',
  '#0F1014',
]

const companyLogosQuery = graphql`
  {
    bdcLogo: file(name: { regex: "/testimonial-logo-bdc/" }) {
      publicURL
    }
    flowLogo: file(name: { regex: "/testimonial-logo-flow/" }) {
      publicURL
    }
    inoviaLogo: file(name: { regex: "/testimonial-logo-inovia/" }) {
      publicURL
    }
    mapleLogo: file(name: { regex: "/testimonial-logo-maple/" }) {
      publicURL
    }
  }
`

function AboutTestimonial() {
  const { flowLogo, bdcLogo, inoviaLogo, mapleLogo } = useStaticQuery(
    companyLogosQuery
  )
  const { height, width } = useResize()

  const testimonials = [
    {
      name: 'Andre Charoo',
      title: 'Founder',
      testimonial:
        'Fey is the modern version of Bloomberg for retail — they are truly empowering retail investors to be profitable in a way that was only reserved for institutional investors in the past. And Narative made it beautiful, accessible and unbelievably fast.',
      logo: mapleLogo,
    },
    {
      name: 'Alex Barrett',
      title: 'Associate',
      testimonial:
        'From the moment I met the Narative team I knew they were special. This team combines a unique eye for design with incredible business acumen that gives them a powerful ability to bring world-changing products to market. I am honoured to be part of their journey.',
      logo: inoviaLogo,
    },
    {
      name: 'David Shemesh',
      title: 'Senior Account Manager',
      testimonial:
        'The Narative team is backed by incredibly talented individuals who were looking to grow their company. BDC was happy to provide non-dilutive growth capital in order to finance their hires and to help with the commercialisation of their projects.',
      logo: bdcLogo,
    },
    {
      name: 'Peter Bailey',
      title: 'Partner',
      testimonial:
        'I’d highly recommend startups at any growth stage to work with Narative. They are brilliant professionals across the board, and thoughtfully approach every conversation with a sincere desire to help you achieve your goals.',
      logo: flowLogo,
    },
  ]

  return (
    <>
      <Desktop>
        <Sticky
          cover
          height={width < 767 ? `1800px` : `2600px`}
          render={({ progress }: StickyState) => {
            const four = progress * 2
            const textStyles =
              progress > 0
                ? ` style="display: block; opacity: ${1 -
                    four}; transform: scale(${1 -
                    progress / 4}); will-change: opacity, transform;"`
                : ``

            return (
              <AboutTestimonialContainer>
                <HeadingLineBreak>
                  <AboutHeading
                    heading="<span>Independent,<br /> but never alone</span>"
                    text={`<span${textStyles}>While we like to do things our own way, nobody can do it all by themselves. <span style="color:#73737D">Narative believes in building ongoing partnerships based on trust, and in contributing our ideas and work to the open source community. When we do honest work, good word spreads, and we can all build ever-greater things.</span></span>`}
                  />
                </HeadingLineBreak>
                <Section narrow>
                  <TestimonialCardContainer>
                    {testimonials.map((testimonial, index) => {
                      const total = testimonials.length
                      const nextIndex = index + 1
                      const previousIndex = index - 1
                      const first = index === 0
                      const minZeroMaxOne = (num: number) => clamp(num, 0, 1)

                      const prevStaggered = minZeroMaxOne(
                        progress - previousIndex / total
                      )
                      const currentStaggered = minZeroMaxOne(
                        progress - index / total
                      )
                      const nextStaggered = minZeroMaxOne(
                        progress - nextIndex / total
                      )

                      const prevProgress = minZeroMaxOne(prevStaggered * total)
                      const currentProgress = minZeroMaxOne(
                        currentStaggered * total
                      )
                      const nextProgress = minZeroMaxOne(nextStaggered * total)

                      let offsetHeight = first ? 100 : 450

                      if (width < 460 && height < 750) {
                        offsetHeight = first ? 180 : 540
                      }

                      if (width < 460 && height < 668) {
                        offsetHeight = first ? 230 : 580
                      }

                      const transalteYFirst = currentProgress * offsetHeight

                      const transalteYSecond =
                        transalteYFirst +
                        nextProgress * 22 * (total - nextIndex)

                      const scaleCurve = 1 - nextStaggered * 0.25

                      const selectedBlend = Math.round(
                        ((((1 - scaleCurve) * 100) / 9) * 10) / 2
                      )

                      return (
                        <TestimonialCard
                          data-card={index}
                          key={testimonial.name}
                          style={{
                            transform: `translateY(-${transalteYSecond}px) scale(${scaleCurve})`,
                            opacity: prevProgress,
                            willChange: 'transform, opacity',
                          }}
                        >
                          <Card
                            style={{
                              background:
                                BACKGROUND_COLOR_BLENDS[selectedBlend],
                            }}
                          >
                            <LogoContainer>
                              <SVG src={testimonial.logo.publicURL} />
                            </LogoContainer>
                            <VerticalDivider />
                            <div>
                              <Title>
                                {testimonial.name} · {testimonial.title}
                              </Title>
                              <Text>{testimonial.testimonial}</Text>
                            </div>
                          </Card>
                        </TestimonialCard>
                      )
                    })}
                  </TestimonialCardContainer>
                </Section>
              </AboutTestimonialContainer>
            )
          }}
        />
      </Desktop>

      <Mobile>
        <HeadingLineBreak>
          <AboutHeading
            heading="Independent,<br /> but never alone"
            text={`While we like to do things our own way, nobody can do it all by themselves. <span style="color:#73737D">Narative believes in building ongoing partnerships based on trust, and in contributing our ideas and work to the open source community. When we do honest work, good word spreads, and we can all build ever-greater things.</span></span>`}
          />
        </HeadingLineBreak>
        <Section narrow>
          <TestimonialCardContainer>
            <HorizontalScroll
              list={testimonials}
              name="testimonial"
              narrow
              render={({ testimonial }: any) => {
                return (
                  <TestimonialCard key={testimonial.name} as="div">
                    <Card>
                      <LogoContainer>
                        <SVG src={testimonial.logo.publicURL} />
                      </LogoContainer>
                      <VerticalDivider />
                      <div style={{ width: '100%' }}>
                        <Name>{testimonial.name}</Name>
                        <Title>{testimonial.title}</Title>
                        <Text>{testimonial.testimonial}</Text>
                      </div>
                    </Card>
                  </TestimonialCard>
                )
              }}
            />
          </TestimonialCardContainer>
        </Section>
      </Mobile>
    </>
  )
}

export default AboutTestimonial

const AboutTestimonialContainer = styled.div`
  position: relative;
  padding-top: 10vh;
  height: 100vh;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    height: 20vh;
    min-height: 200px;
    right: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(11, 12, 15, 0) 0%, #090a0d 80%);
    pointer-events: none;

    @media (min-height: 800px) {
      height: 25vh;
      min-height: 300px;
      background: linear-gradient(180deg, rgba(11, 12, 15, 0) 0%, #090a0d 60%);
    }

    @media (min-height: 950px) {
      height: 35vh;
      min-height: 400px;
      background: linear-gradient(180deg, rgba(11, 12, 15, 0) 0%, #090a0d 50%);
    }

    @media (min-height: 1050px) {
      height: 40vh;
      min-height: 400px;
      background: linear-gradient(180deg, rgba(11, 12, 15, 0) 0%, #090a0d 30%);
    }

    ${media.tablet`
      height: 20vh;
      min-height: 100px;
    `}

    ${media.phone_small`
      height: 10vh;
      min-height: auto;
      background: linear-gradient(180deg, rgba(11, 12, 15, 0) 0%, #0b0c0f 70%);
    `}
  }

  ${media.phone_small`
    padding-top: 5vh;
  `}
`

const TestimonialCardContainer = styled.ul`
  position: relative;
  max-width: 750px;
  margin: 90px auto 0;
  list-style: none;

  ${media.desktop`
    margin: 50px auto 0;
  `}
`

const TestimonialCard = styled.li`
  top: 350px;
  height: 350px;
  position: absolute;

  &:first-child {
    top: 0;
  }

  ${media.desktop`
    position: relative;
    top: 0;
    height: auto;
    margin-bottom: 20px;
  `}
`

const Card = styled.div`
  display: flex;
  align-items: center;
  height: 200px;
  width: 100%;
  border-radius: 5px;
  background: #1d2128;
  padding: 40px 40px 40px 0;
  font-size: 18px;
  box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.35);
  transition: background 0.1s;

  ${media.tablet`
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    height: auto;
    padding: 30px 30px 0;
  `}

  ${media.phablet`
    padding: 30px 15px 0;
    min-height: 365px;
  `}
`

const LogoContainer = styled.div`
  ${media.tablet`
    width: 100%;
    border-top: 1px solid rgba(250, 250, 250, 0.05);
    order: 3;
  `}
`

const Name = styled.div`
  font-weight: 700;
  color: #fff;
  white-space: pre-wrap;
`

const Title = styled.div`
  font-weight: 700;
  color: #fff;

  ${media.tablet`
  font-weight: 400;
    margin-bottom: 22px;
    white-space: pre-wrap;
  `}
`

const Text = styled.p`
  color: ${p => p.theme.colors.grey};

  ${media.tablet`
    padding-bottom: 30px;
  `}

  ${media.phablet`
    font-size: 16px;
    white-space: pre-wrap;
    padding-bottom: 20px;
  `}
`

const VerticalDivider = styled.div`
  height: 120px;
  width: 5px;
  margin-right: 40px;
  background: rgba(250, 250, 250, 0.05);

  ${media.tablet`
    display: none;
  `}
`

const HeadingLineBreak = styled.div`
  h2 {
    width: 70%;
    display: block;

    ${media.tablet`
      width: 80%;
    `}

    ${media.phone`
      width: 90%;
    `}
  }
`

const Desktop = styled.div`
  ${media.desktop`
    display: none;
  `}
`

const Mobile = styled.div`
  display: none;

  ${media.desktop`
    position: relative;
    display: block;
    margin: 100px auto 160px;
  `}

  ${media.phablet`
    margin: 100px auto;
  `}
`
